import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'

const GridWrapper = styled.div`
  margin: 0 0 2rem 0;
`

const Card = styled.div`
  border-bottom: 2px solid #eee;
  padding: 1rem;

  &:first-child {
    border-top: 2px solid #eee;
  }
`

const Row = styled.div`
  display: flex;
  padding: 0.5rem 0;
`

const Cell = styled.div.attrs((props) => ({
  color: props.color || '',
  size: props.size || '',
}))`
  word-break: break-word;
  color: ${(props) => props.color};
  flex: ${(props) => props.size};
  text-align: right;
  flex: 1;
`

const NoData = styled.div`
  text-align: center;
  line-height: 60px;
`

const CardGrid = (props) => (
  <GridWrapper>
    {props.data.length > 0 && (
      <>
        {props.data.map((row, index) => {
          return (
            <Card key={`row-${index}`}>
              {props.columns.map((column, columnIndex) => {
                let cell = row[column.columnName]

                if (typeof cell.value === 'string') {
                  return (
                    <Row key={columnIndex}>
                      <div>{column.displayName}</div>
                      <Cell
                        color={cell?.color}
                        dangerouslySetInnerHTML={{
                          __html: cell !== undefined ? cell.value : '',
                        }}
                      />
                    </Row>
                  )
                } else {
                  return (
                    <Row key={columnIndex}>
                      <div>{column.displayName}</div>
                      <Cell color={cell?.color}>{cell?.value}</Cell>
                    </Row>
                  )
                }
              })}
            </Card>
          )
        })}
      </>
    )}
    {props.data.length === 0 && (
      <NoData>
        <FormattedMessage id='grid.noDataAvailable' />
      </NoData>
    )}
  </GridWrapper>
)

export default CardGrid
