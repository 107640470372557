import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { addMinutes } from 'date-fns'
import { format } from 'date-fns-tz'

import circle_solid_grey from '../../images/circle_solid_grey.svg'
import circle_solid_green from '../../images/circle_solid_green.svg'
import { getUserLimitPeriods, setUserLimit } from '../../adapters/user'
import Btn from '../../components/common/button'
import Modal from '../../components/common/modal'
import { AuthContext } from '../../context/authProvider'
import toast from '../../components/common/toast'

dayjs.extend(utc)

const Wrapper = styled.div`
  margin: 2rem 0;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`

const Info = styled.p`
  margin: 2rem 0;
  font-size: 0.9em;
`

const SectionHeader = styled.div`
  line-height: 1.4em;
  font-size: 1.412em;
  font-weight: 600;
  margin: 1rem 0 0.8rem 0;
`

const CheckboxWrapper = styled.div`
  font-size: 0.9em;
  display: flex;
  position: relative;
  cursor: pointer;
  border-radius: 6px;
  padding: 1rem;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked ~ label {
      &:before {
        background-color: #fff;
        border: 3px solid #31c27c;
        color: #31c27c;
        background-image: url(${circle_solid_green});
      }
    }
  }

  label {
    padding: 0 0 0 2rem;

    &.checked {
      &:before {
        background-color: #fff;
        border: 3px solid #31c27c;
        color: #31c27c;
        background-image: url(${circle_solid_green});
      }
    }

    &:before {
      display: block;
      position: absolute;
      user-select: none;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-image: url(${circle_solid_grey});
      background-color: #fff;
      background-size: 86%;
      background-repeat: no-repeat;
      background-position: center;
      border: 3px solid #dbdbdb;
      pointer-events: none;
      content: '';
      left: 0;
      top: 0.5rem;
      box-sizing: border-box;
    }

    &:after {
      content: '';
      left: 0;
      top: 0;
      background: no-repeat 50%/50% 50%;
      display: block;
      width: 1rem;
      height: 1rem;
      position: absolute;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.invalid {
    label {
      &:before {
        border: 3px solid #ff8282;
      }
    }
  }
`

const dateFormat = 'yyyy-MM-dd HH:mm'

const SelfExclusion = (props) => {
  const { type } = props

  const intl = useIntl()

  const { logoutUser } = useContext(AuthContext)

  const [setting, setSetting] = useState(null)
  const [periods, setPeriods] = useState([])
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const date = new Date()
  const today = addMinutes(date, date.getTimezoneOffset())

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    const fetchPeriods = async () => {
      const response = await getUserLimitPeriods()
      if (response.ok) setPeriods(response.data.data)
    }

    fetchPeriods()
  }, [])

  const hoursToMinutes = (hours) => hours * 60
  const getCoolOffValues = (_) =>
    periods.filter(
      (limit) =>
        limit.name === 'Daily' ||
        limit.name === 'Monthly' ||
        limit.name === 'Weekly'
    )
  const getSelfExclusionValues = (_) =>
    periods.filter(
      (limit) => limit.name === 'Yearly' || limit.name === 'HalfYearly'
    )

  const handleChange = (data) => {
    setSetting(data)
  }

  const handleConfirmation = async () => {
    const model = {
      period_id: setting.id,
      name: setting.name,
      duration: hoursToMinutes(setting.duration),
      type: type === 'cool_off_limit' ? 'COOL_OFF_LIMIT' : 'SELF_EXCLUSION',
      reason: '',
    }

    const response = await setUserLimit(model)
    if (response.ok) {
      toast.success(
        intl.formatMessage({
          id: `account.limits.selfExcludeSuccess_SelfExclusion${model.name}`,
        })
      )
      setShowConfirmationModal(false)
      logoutUser()
    }
  }

  const onSubmit = () => {
    setShowConfirmationModal(true)
  }

  const renderValues = (values) => {
    const translationKey =
      type === 'self_exclusion' ? 'SelfExclusion' : 'CoolOff'
    return values.map((value, index) => {
      return (
        <CheckboxWrapper key={index} onClick={() => handleChange(value)}>
          <label
            className={`${setting?.id === value.id ? 'checked' : ''} ${
              errors.selfExclusionSetting ? 'invalid' : ''
            }`}
            htmlFor={`${translationKey}${value.name}`}
          >
            <FormattedMessage
              id={`account.limits.${translationKey}${value.name}`}
            />
          </label>
          <input
            type='radio'
            checked={`${setting?.id === value.id ? 'checked' : ''}`}
            {...register(
              type === 'self_exclusion'
                ? 'selfExclusionSetting'
                : 'coolOffSetting',
              { required: true }
            )}
          />
        </CheckboxWrapper>
      )
    })
  }

  return (
    <Wrapper>
      <Info>
        {type === 'self_exclusion' && (
          <FormattedMessage id='account.limits.selfExclusionDescription' />
        )}
        {type === 'cool_off_limit' && (
          <FormattedMessage id='account.limits.coolOffDescription' />
        )}
      </Info>

      <SectionHeader>
        {type === 'self_exclusion' && (
          <FormattedMessage id='account.limits.selfExclusionTitle' />
        )}
        {type === 'cool_off_limit' && (
          <FormattedMessage id='account.limits.coolOffTitle' />
        )}
      </SectionHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          {type === 'cool_off_limit' && renderValues(getCoolOffValues())}
          {type === 'self_exclusion' && renderValues(getSelfExclusionValues())}

          <input
            type='submit'
            className='secondary'
            value={
              type === 'cool_off_limit'
                ? intl.formatMessage({ id: 'account.limits.coolOff' })
                : intl.formatMessage({ id: 'account.limits.selfExclude' })
            }
          />
        </>
      </form>

      {showConfirmationModal && (
        <Modal
          title={intl.formatMessage({ id: 'account.pleaseConfirm' })}
          isOpen={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
          bodyMinHeight={false}
        >
          <p>
            <FormattedMessage id={`account.confirmationMessage`} />
            {` 
              (${format(today, dateFormat)})
               - 
              (${format(
                addMinutes(today, hoursToMinutes(setting.duration)),
                dateFormat
              )})
            `}
          </p>

          <Row>
            <Btn
              text={intl.formatMessage({ id: 'common.confirm' })}
              bgcolor='#31c27c'
              padding='.32rem 1rem'
              bordercolor='#31c27c'
              fontSize='.85em'
              onClick={handleConfirmation}
            />
            <Btn
              text={intl.formatMessage({ id: 'common.cancel' })}
              padding='.32rem 1rem'
              color='#000'
              fontSize='.85em'
              onClick={() => setShowConfirmationModal(false)}
            />
          </Row>
        </Modal>
      )}
    </Wrapper>
  )
}

export default SelfExclusion
