import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import media from '../../components/css/media'
import {
  getUserLimitPeriods,
  setUserLimit,
  getUserLimits,
  getUserScheduledLimits,
  setUserUpdateLimit,
} from '../../adapters/user'
import Grid from '../../components/common/grid'
import toast from '../../components/common/toast'

dayjs.extend(utc)

const Info = styled.p`
  margin: 2rem 0;
  font-size: 0.9em;
`

const SectionHeader = styled.div`
  line-height: 1.4em;
  font-size: 1.412em;
  font-weight: 600;
  margin: 1rem 0 0.8rem 0;
`

const FilterWrapper = styled.div`
  display: none;

  ${media.tablet`
    display: flex;
    width: 60%;
    gap: 20px;
    margin: 1.5rem 0;
  `};

  input {
    margin: 0 !important;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  &:nth-child(2) {
    justify-content: flex-end;
  }

  span {
    position: absolute;
    top: 35px;
    right: 15px;
    text-transform: uppercase;
  }
`

const SessionLimit = (props) => {
  const { type } = props

  const intl = useIntl()

  const [periods, setPeriods] = useState([])
  const [limits, setLimits] = useState([])

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm()

  const columns = [
    {
      columnName: 'amount',
      displayName: intl.formatMessage({ id: 'account.limits.amount' }),
      size: 3,
    },
    {
      columnName: 'status',
      displayName: intl.formatMessage({ id: 'account.limits.status' }),
      size: 3,
    },
    {
      columnName: 'expiry',
      displayName: intl.formatMessage({ id: 'account.limits.time' }),
      size: 3,
    },
  ]

  useEffect(() => {
    const fetchPeriods = async () => {
      const response = await getUserLimitPeriods()
      if (response.ok) setPeriods(response.data.data)
    }

    fetchPeriods()
    fetchLimits()
  }, [])

  const onSubmit = async (data) => {
    const yearlyPeriod = periods.filter((limit) => limit.name === 'Yearly')[0]
    const activeLimits = limits.filter(
      (l) =>
        l.type === 'gaming_session' &&
        l.status === 'ACTIVE' &&
        l.period.id === yearlyPeriod.id
    )

    const model =
      activeLimits.length === 0
        ? {
            amount: data.amount,
            period_id: yearlyPeriod.id,
            duration: periods.filter((l) => l.id === yearlyPeriod.id)[0]
              .duration,
            type: 'GAMING_SESSION',
          }
        : {
            id: activeLimits[0].id,
            amount: data.amount,
            duration: activeLimits[0].period.duration,
            type: 'GAMING_SESSION',
          }

    const response =
      activeLimits.length === 0
        ? await setUserLimit(model)
        : await setUserUpdateLimit(model)

    if (response.ok) {
      fetchLimits()
      toast.success(
        intl.formatMessage({ id: 'account.limits.limitAddedSuccess' })
      )
    }
  }

  const fetchLimits = () => {
    setLimits([])

    getUserLimits().then((res) => {
      let cnt = 1
      res.data.data.forEach((row) => {
        getUserScheduledLimits(row.id).then((response) => {
          row.scheduledLimits = response.data.data

          if (cnt === res.data.data.length) {
            setLimits(res.data.data)
          }

          cnt++
        })
      })
    })
  }

  const formatLimitsData = (limits, type) => {
    let data = []

    limits = limits.filter((l) => l.type.toLowerCase() === 'gaming_session')

    limits.forEach((row) => {
      data.push({
        duration: {
          value: intl.formatMessage({
            id: `account.limits.${row.period.name.toLowerCase()}`,
          }),
        },
        amount: {
          value: `${row.amount} ${intl.formatMessage({
            id: `account.limits.minutes`,
          })}`,
        },
        status: {
          value: intl.formatMessage({
            id: `account.limits.${row.status.toLowerCase()}`,
          }),
          color: '#31C27C',
        },
        expiry: {
          value:
            row?.scheduledLimits?.length === 0
              ? intl.formatMessage({ id: 'account.limits.doesNotExpire' })
              : intl
                  .formatMessage({ id: 'account.limits.expiresOn' })
                  .replace(
                    '{0}',
                    dayjs
                      .utc(row.scheduledLimits[0]?.execute_at)
                      .format('DD.MM.YYYY HH:mm:ss')
                  ),
        },
      })

      if (row?.scheduledLimits?.length > 0) {
        data.push({
          duration: {
            value: intl.formatMessage({
              id: `account.limits.${row.period.name.toLowerCase()}`,
            }),
          },
          amount: {
            value: `${row.scheduledLimits[0].amount} ${intl.formatMessage({
              id: `account.limits.minutes`,
            })}`,
          },
          status: {
            value: intl.formatMessage({ id: 'account.limits.pending' }),
            color: '#FFB300',
          },
          expiry: {
            value: intl
              .formatMessage({ id: 'account.limits.activeOn' })
              .replace(
                '{0}',
                dayjs
                  .utc(row.scheduledLimits[0]?.execute_at)
                  .format('DD.MM.YYYY HH:mm:ss')
              ),
          },
        })
      }
    })

    return data
  }

  return (
    <>
      <Info>
        <FormattedMessage id='account.limits.gaming_sessionLimitDescription' />
      </Info>

      <SectionHeader>
        <FormattedMessage id='account.limits.gaming_sessionLimitTitle' />
      </SectionHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FilterWrapper>
          <Col>
            <label htmlFor='enterValue'>
              <FormattedMessage id='account.limits.enterValue' />
            </label>
            <input
              type='number'
              defaultValue={0}
              className={`${errors.amount ? 'invalid' : ''} ${
                !errors.amount && touchedFields.amount ? 'valid' : ''
              }`}
              {...register('amount', { required: true })}
              id='enterValue'
            />
            <span>
              <FormattedMessage id='account.limits.minutes' />
            </span>
          </Col>
          <Col>
            <input
              type='submit'
              className='secondary'
              value={intl.formatMessage({ id: 'account.limits.setLimit' })}
            />
          </Col>
          <Col />
        </FilterWrapper>
      </form>

      <SectionHeader>
        <FormattedMessage id='account.limits.gaming_sessionLimitsList' />
      </SectionHeader>

      <Grid columns={columns} data={formatLimitsData(limits, type)} />
    </>
  )
}

export default SessionLimit
