import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import media from '../../components/css/media'
import storageService from '../../services/storageService'
import {
  getUserLimitPeriods,
  setUserLimit,
  getUserLimits,
  getUserScheduledLimits,
  setUserUpdateLimit,
} from '../../adapters/user'
import Grid from '../../components/common/grid'
import CardGrid from '../../components/common/cardGrid'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import toast from '../../components/common/toast'
import FormatAmount from '../../components/common/formatAmount'

dayjs.extend(utc)

const Info = styled.p`
  margin: 2rem 0;
  font-size: 0.9em;
`

const SectionHeader = styled.div`
  line-height: 1.4em;
  font-size: 1.412em;
  font-weight: 600;
  margin: 1rem 0 0.8rem 0;
`

const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  flex-direction: column;

  ${media.tablet`
    width: 60%;
    margin: 1.5rem 0;
    flex-direction: row;
  `};

  input {
    margin: 0 !important;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &:last-child {
    justify-content: center;
  }
`

const InputWrapper = styled.div`
  position: relative;

  input {
    padding-right: 50px;
  }
  span {
    position: absolute;
    top: 15px;
    right: 15px;
  }
`

const SetLimits = (props) => {
  const { type } = props

  const user = storageService.getUser()
  const intl = useIntl()
  const { isMobile } = useDeviceDetect()

  const [periods, setPeriods] = useState([])
  const [limits, setLimits] = useState([])

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm()

  const columns = [
    {
      columnName: 'duration',
      displayName: intl.formatMessage({ id: 'account.limits.duration' }),
      size: 3,
    },
    {
      columnName: 'amount',
      displayName: intl.formatMessage({ id: 'account.limits.amount' }),
      size: 3,
    },
    {
      columnName: 'status',
      displayName: intl.formatMessage({ id: 'account.limits.status' }),
      size: 3,
    },
    {
      columnName: 'expiry',
      displayName: intl.formatMessage({ id: 'account.limits.time' }),
      size: 3,
    },
  ]

  useEffect(() => {
    const fetchPeriods = async () => {
      const response = await getUserLimitPeriods()
      if (response.ok) setPeriods(response.data.data)
    }

    fetchPeriods()
    fetchLimits()
  }, [])

  const onSubmit = async (data) => {
    const activeLimits = limits.filter(
      (l) =>
        l.type === type.toUpperCase() &&
        l.status === 'ACTIVE' &&
        l.period.id === parseInt(data.duration)
    )

    const model =
      activeLimits.length === 0
        ? {
            amount: data.amount,
            period_id: data.duration,
            duration: periods.filter((l) => l.id === parseInt(data.duration))[0]
              .duration,
            type: type.toUpperCase(),
          }
        : {
            id: activeLimits[0].id,
            amount: data.amount,
            duration: activeLimits[0].period.duration,
            type: type.toUpperCase(),
          }

    const response =
      activeLimits.length === 0
        ? await setUserLimit(model)
        : await setUserUpdateLimit(model)

    if (response.ok) {
      fetchLimits()
      toast.success(
        intl.formatMessage({ id: 'account.limits.limitAddedSuccess' })
      )
    }
  }

  const fetchLimits = () => {
    setLimits([])

    getUserLimits().then((res) => {
      let cnt = 1
      res.data.data.forEach((row) => {
        getUserScheduledLimits(row.id).then((response) => {
          row.scheduledLimits = response.data.data

          if (cnt === res.data.data.length) {
            setLimits(res.data.data)
          }

          cnt++
        })
      })
    })
  }

  const formatLimitsData = (limits, type) => {
    let data = []

    const user = storageService.getUser()
    limits = limits.filter((l) => l.type.toLowerCase() === type.toLowerCase())

    limits.forEach((row) => {
      data.push({
        duration: {
          value: intl.formatMessage({
            id: `account.limits.${row.period.name.toLowerCase()}`,
          }),
        },
        amount: {
          value: (
            <FormatAmount
              amount={row.amount}
              currency={user.wallet.currency.short_code}
            />
          ),
        },
        status: {
          value: intl.formatMessage({
            id: `account.limits.${row.status.toLowerCase()}`,
          }),
          color: '#31C27C',
        },
        expiry: {
          value:
            row?.scheduledLimits?.length === 0
              ? intl.formatMessage({ id: 'account.limits.doesNotExpire' })
              : intl
                  .formatMessage({ id: 'account.limits.expiresOn' })
                  .replace(
                    '{0}',
                    dayjs
                      .utc(row.scheduledLimits[0]?.execute_at)
                      .format('DD.MM.YYYY HH:mm:ss')
                  ),
        },
      })

      if (row?.scheduledLimits?.length > 0) {
        data.push({
          duration: {
            value: intl.formatMessage({
              id: `account.limits.${row.period.name.toLowerCase()}`,
            }),
          },
          amount: {
            value: (
              <FormatAmount
                amount={row.scheduledLimits[0].amount}
                currency={user.wallet.currency.short_code}
              />
            ),
          },
          status: {
            value: intl.formatMessage({ id: 'account.limits.pending' }),
            color: '#FFB300',
          },
          expiry: {
            value: intl
              .formatMessage({ id: 'account.limits.activeOn' })
              .replace(
                '{0}',
                dayjs
                  .utc(row.scheduledLimits[0]?.execute_at)
                  .format('DD.MM.YYYY HH:mm:ss')
              ),
          },
        })
      }
    })

    return data
  }

  return (
    <>
      <Info>
        {type === 'deposit' && (
          <FormattedMessage id='account.limits.depositLimitDescription' />
        )}
        {type === 'stop_loss' && (
          <FormattedMessage id='account.limits.stop_lossLimitDescription' />
        )}
        {type === 'wager' && (
          <FormattedMessage id='account.limits.wagerLimitDescription' />
        )}
      </Info>

      <SectionHeader>
        {type === 'deposit' && (
          <FormattedMessage id='account.limits.depositLimitTitle' />
        )}
        {type === 'stop_loss' && (
          <FormattedMessage id='account.limits.stop_lossLimitTitle' />
        )}
        {type === 'wager' && (
          <FormattedMessage id='account.limits.wagerLimitTitle' />
        )}
      </SectionHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FilterWrapper>
          <Col>
            <label htmlFor='amount'>
              <FormattedMessage id='account.limits.enterAmount' />
            </label>
            <InputWrapper>
              <input
                type='number'
                defaultValue={0}
                className={`${errors.amount ? 'invalid' : ''} ${
                  !errors.amount && touchedFields.amount ? 'valid' : ''
                }`}
                {...register('amount', { required: true })}
                id='amount'
              />
              <span>{user.wallet.currency.short_code}</span>
            </InputWrapper>
          </Col>
          <Col>
            <label htmlFor='duration'>
              <FormattedMessage id='account.limits.duration' />
            </label>
            <select
              className={`${errors.duration ? 'invalid' : ''} ${
                !errors.duration && touchedFields.duration ? 'valid' : ''
              }`}
              {...register('duration', { required: true })}
              id='duration'
            >
              <option value=''>
                {intl.formatMessage({ id: 'account.limits.selectLimit' })}
              </option>
              {periods
                .filter(
                  (limit) =>
                    limit.name === 'Daily' ||
                    limit.name === 'Weekly' ||
                    limit.name === 'Monthly'
                )
                .map((value, index) => {
                  return (
                    <option key={index} value={value.id}>
                      {intl.formatMessage({
                        id: `account.limits.${value.name.toLowerCase()}`,
                      })}
                    </option>
                  )
                })}
            </select>
          </Col>
          <Col>
            <input
              type='submit'
              className='secondary'
              value={intl.formatMessage({ id: 'account.limits.setLimit' })}
            />
          </Col>
        </FilterWrapper>
      </form>

      <SectionHeader>
        {type === 'deposit' && (
          <FormattedMessage id='account.limits.depositLimitsList' />
        )}
        {type === 'stop_loss' && (
          <FormattedMessage id='account.limits.stop_lossLimitsList' />
        )}
        {type === 'wager' && (
          <FormattedMessage id='account.limits.wagerLimitsList' />
        )}
      </SectionHeader>

      {!isMobile && (
        <Grid columns={columns} data={formatLimitsData(limits, type)} />
      )}

      {isMobile && (
        <CardGrid columns={columns} data={formatLimitsData(limits, type)} />
      )}
    </>
  )
}

export default SetLimits
